export const useNewsStore = defineStore('news', () => {
  /* === init === */
  const { $directus, $readItems } = useNuxtApp()
  const dataEnv = useRuntimeConfig().public.dataEnv

  /* === state === */
  const news = ref([])

  /* === actions === */
  async function init() {
    try {
      const data = await $directus().request(
        $readItems('news', {
          filter: {
            envs: { _contains: dataEnv },
          },
          fields: [
            '*',
            {
              translations: ['*'],
            },
          ],
          sort: '-date_published',
        })
      )
      news.value = data
    } catch (err) {
      console.warn(err)
    }
  }

  return {
    // state
    news,
    // actions
    init,
  }
})
