<script setup>
const { t } = useI18n()

defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const areIframesEnabled = useTestCookieId('media-iframes')

function enableIframes() {
  useEnableCookieId('media-iframes')
}
</script>
<template>
  <!-- eslint-disable vue/no-v-html -->
  <section
    :class="['block-embed', 'block-embed-' + data.type]"
    :style="{ '--aspect-ratio': data.aspect_ratio || '3/1' }"
  >
    <div v-if="data.type === 'audio'" class="block-audio">Not implemented</div>
    <div v-if="data.type === 'iiif'" class="block-iiif">Not implemented</div>
    <div v-if="data.type === 'iframe'" class="block-iframe">
      <div v-if="areIframesEnabled" v-html="data.code"></div>

      <div v-else class="cookie-proposal-wrapper">
        <div class="cookie-proposal">
          <p>
            {{ t(`cookies.generalIFrameDeclaration`) }}
          </p>
          <button @click="enableIframes">
            {{ t(`cookies.acceptGeneralMediaCookies`) }}
          </button>
        </div>
      </div>

      <div></div>
    </div>

    <div v-else-if="data.type === 'video-file'" class="block-video-file">
      <video
        :src="useDirectusFile(data.file.id)"
        controls
        playsinline
        preload="metadata"
      ></video>
    </div>
    <div
      v-else-if="data.type === 'image'"
      class="block-image"
      :style="'--max-width: ' + data.max_width + 'rem'"
    >
      <NuxtLink
        :to="data.link"
        :target="targetForURL(data.link)"
        class="image-link"
      >
        <CaptionedImage
          v-if="data.file"
          :src="useDirectusImgSrc(data.file.id)"
          :image="data.file"
        />
      </NuxtLink>
      <p v-if="data.file.description" class="image-caption">
        {{ data.file.description }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.block-embed {
  max-width: 70em;
  margin: 4rem auto;
  :deep(a) {
    border-bottom: 1px solid var(--color-text);
  }
}

.block-iframe {
  position: relative;
  width: 100%;
  :deep(iframe) {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: var(--aspect-ratio);
  }
}

.block-video-file {
  height: 100%;
  video {
    margin: 0 auto;
    width: 100%;
    aspect-ratio: var(--aspect-ratio);
  }
}
.block-image {
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-width, 100%);
  padding: 0 var(--layout-margin-h);
  .image-link {
    border: none;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    display: inline-block;
    @include action-state-colors;
  }
  .image-caption {
    text-align: center;
    @extend %font-caption;
  }
}

.cookie-proposal-wrapper {
  aspect-ratio: var(--aspect-ratio);
  width: 100%;
  background-color: var(--color-background-soft);
  display: flex;
  align-items: center;
}

.cookie-proposal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 0 10%;
  text-align: center;

  button {
    @extend %button;
    margin-top: 1rem;
  }
}
</style>
